<template>
	<div>
		<add-project-modal ref="addProjectModal" />

		<div class="row mb-0">
			<div class="col-sm-6">
				<div class="form-group">
					<button type="submit" class="btn btn-outline-primary" @click.prevent="$refs.addProjectModal.openModal()">Создать проект</button>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="text-sm-right">
					<div class="btn-group mb-3 mr-2">
						<router-link class="btn btn-light" :to="{ name: 'ProjectList' }">
							Активные
						</router-link>
						<router-link class="btn btn-primary" :to="{ name: 'ProjectListFinished' }">
							Завершенные
						</router-link>
					</div>
				</div>
			</div><!-- end col-->
		</div>


		<div class="row" v-if="items === null">
			<div class="col-md-12 text-center">
				<div class="spinner-grow" role="status">
					<span class="sr-only">Загрузка...</span>
				</div>
			</div>
		</div>

		<div v-else>
			<masonry ref="masonry" :cols="{default: 2, 1200: 1}" :gutter="{default: '30px', 1200: '10px'}">
				<project-card v-for="(item, i) in filteredItems" :key="item.id" :project="item"/>
			</masonry>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import log from '@/plugins/log'

import AddProjectModal from '@/components/modal/AddProject'
import ProjectCard from '@/components/project/Card'
import ProjectCardFinished from '@/components/project/FinishedCard'

export default {
	name: 'ProjectList',
	data: () => {
		return {
			items: null,
			unsubsribe: null,
			timers: null
		}
	},
	computed: {
		...mapState([
			'current_timer'
		]),
		filteredItems() {
			return this.items
		}
	},
	async beforeMount() {
		await this.data()
	},
	beforeRouteLeave(to, from , next) {
		if(typeof this.unsubsribe == 'function') 
			this.unsubsribe()
		next()
	},
	methods: {
		async data() {
			this.unsubsribe = await this.$orm.queryAndSubscribe({
				project: {
                    where: {
						is_finished: { _eq: true }
					},
					order_by: { updated_at: 'desc' },
					fragment: 'list'
				}
			}, ([err, response]) => {
				if(err) {
					log.error('/views/project/list', 'methods.data', '$orm.queryAndSubscribe', err)
					return $.NotificationApp.send("Error", "Can't send request", "top-right", "", "error")
				}
				log.debug('/views/project/list', 'methods.data', '$orm.queryAndSubscribe', response)
                this.items = response
                
                this.$nextTick(() => {
					this.$refs.masonry.reCalculate();
				});
			})
		},
	},
	components: {
		AddProjectModal,
		ProjectCard,
		ProjectCardFinished
	}
}
</script>

<style scoped>
.description {
	white-space: pre-line;
}
.task-list .custom-checkbox label {
	color: rgba(0, 0, 0, 0);
	cursor: pointer;
}
</style>